import { Component, OnInit } from '@angular/core';
import { SlidesOutputData, OwlOptions } from 'ngx-owl-carousel-o';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/services/service.service';
import { MessageService } from 'src/app/services/message.service';
import { PageTitleService } from 'src/app/services/page-title.service';





@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  serviceForm: FormGroup;
  submitted = false;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private msg: MessageService,
    private pageTitle: PageTitleService
  ) {
    this.createForm();
    this.pageTitle.setTitle('Welcome To WaafiPay')
   }

  ngOnInit(): void {
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  createForm() {
    this.serviceForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  send(){
    this.submitted = true;
    if(this.serviceForm.invalid)return;

    let payload:any = {
      name: this.serviceForm.value.name,
      from: this.serviceForm.value.email,
      subject: this.serviceForm.value.subject,
      text: this.serviceForm.value.message
    }
    this.service.send(payload).subscribe((res) => {
      if(!res.success){
        this.msg.showErrorMessage(res.error)
      }else{
        this.msg.showSuccessMessage(res.msg)
        this.serviceForm.reset();
        this.submitted = false;
      }
      // console.log("DATA",res)
    })
  }

}
