import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  url = 'http://localhost:9000';

  constructor(
    private http: HttpClient
  ) { }

  send(data){
    return this.http.post(this.url + '/send', data).pipe(map((res: any) => res)
    );
  }
}
