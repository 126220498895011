  <!-- ======= Footer ======= -->
  <footer id="footer">

    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-contact">
            <h3>WaafiPay</h3>
            <p>Baniyas Square, Al-rigga</p>
            <p>Dubai, United Arab Emirates<br>
              <strong>Phone:</strong> +971 50 380 5023<br>
              <strong>Phone:</strong> +971 54 301 5000<br>
              <strong>Email:</strong> info@waafipay.net<br>
            </p>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="/">Home</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="/">Services</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="https://developer.waafipay.net/" target="_blank">Developer</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="/about-us">About Us</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="https://merchant.waafipay.net" target="_blank">My Account</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="/merchant-services">Merchant Services</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="/tap-and-pay">Tap & Pay (Tapsii)</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="/wages-protection">Wages Protection</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="/credit-cars">Credit Cards</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="/easy-integration">Purchase Integration</a></li>
            </ul>
          </div>

          <div class="col-lg-4 col-md-6 footer-newsletter">
            <h4>Email</h4>
            <p>Enter your email And Subscribe</p>
            <form action="" method="post">
              <input type="email" name="email"><input type="submit" value="Subscribe">
            </form>
          </div>

        </div>
      </div>
    </div>

    <div class="container d-lg-flex py-4">

      <div class="mr-lg-auto text-center text-lg-left">
        <div class="copyright">
          &copy; Copyright <strong><span>Safarifone .Inc</span></strong>. All Rights Reserved
        </div>
      </div>
      <div class="social-links text-center text-lg-right pt-3 pt-lg-0">
        <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
        <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
        <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
        <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
        <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
      </div>
    </div>
  </footer><!-- End Footer -->
