import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";

import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing/app-routing.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ClientsComponent } from './components/clients/clients.component';
import { MainComponent } from './components/main/main.component';
import { MerchantServicesComponent } from './components/contents/merchant-services/merchant-services.component';
import { HomeComponent } from './components/contents/home/home.component';
import { AboutUsComponent } from './components/contents/about-us/about-us.component';
import { TapAndPayComponent } from './components/contents/tap-and-pay/tap-and-pay.component';
import { CreditCarsComponent } from './components/contents/credit-cars/credit-cars.component';
import { WagesProtectionComponent } from './components/contents/wages-protection/wages-protection.component';
import { DemoComponent } from './components/contents/demo/demo.component';
import { WooCommerceComponent } from './components/contents/web-inegration/woo-commerce/woo-commerce.component';
import { ShopifyComponent } from './components/contents/web-inegration/shopify/shopify.component';
import { OpenCartComponent } from './components/contents/web-inegration/open-cart/open-cart.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ClientsComponent,
    MainComponent,
    MerchantServicesComponent,
    HomeComponent,
    AboutUsComponent,
    TapAndPayComponent,
    CreditCarsComponent,
    WagesProtectionComponent,
    DemoComponent,
    WooCommerceComponent,
    ShopifyComponent,
    OpenCartComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CarouselModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
