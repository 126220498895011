
<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex flex-column justify-content-center align-items-center" style="margin-bottom: 0px !important;">
    <div class="container" data-aos="fade-in">
      <div class="row">
        <div class="col-6">
          <h1>The payment platform for cross-border businesses.</h1>
          <p>Our solution is conveniently integrated into the world’s leading e-commerce platforms as well as available directly to our customers who prefer to use our state of the art payment APIs.</p>
          <div class="d-flex align-items-center">
            <i class="bx bx-down-arrow-alt get-started-icon"></i>
            <a (click)="onClick()" style="cursor: pointer;" class="btn-get-started scrollto">Learn offers Our Merchant</a>
          </div>
        </div>
        <div class="col-6">
          <img src="../assets/img/banner/merchant-banner-2.png" style="margin-left: 35px;" width="110%" height="100%" alt="" srcset="">
        </div>
      </div>
     
    </div>
  </section>
   <!-- ======= offers Section ======= -->
   <section id="offers" class="offers section-bg">
    <div class="container">
      <div class="row">
        <div class="col-5  justify-content-center align-items-stretch" data-aos="fade-right">
            <img src="../assets/img/banner/merchant-banner-4.png" width="100%" height="100%" alt="" srcset="">
        </div>

        <div class="col-7  align-items-stretch justify-content-center">
          <h3 data-aos="fade-up">WaaafiPay offers businesses the solutions to deliver outstanding & enhanced customer experience</h3>
    

          <div class="icon-box" data-aos="fade-up">
            <div class="icon"><i class="icofont-verification-check"></i></div>
            <h4 class="title"><a href="">Local Payment Options - <span style="font-weight: 100;">Our payment gateway is letting people pay in their preferred way, a merchant potentially increases his sales volumes! So, there are different forms of local payment methods including e-wallets, bank cards, vouchers, and prepaid and pre-authorized cards.</span> </a></h4>
            
          </div>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon"><i class="icofont-verification-check"></i></div>
            <h4 class="title"><a href="">Global Scale  - <span style="font-weight: 100;">Waafipay can deliver a cost-effective, one-stop solution and supported by the largest collection of local payment methods across Middle East & Africa. our payment gateway has expanded around the world an every business and customers was interacting their needs.</span></a></h4>
    
          </div>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
            <div class="icon"><i class="icofont-verification-check"></i></div>
            <h4 class="title"><a href="">Ecommerce Ready - <span style="font-weight: 100;">Ecommerce, also known as electronic commerce or internet commerce, refers to the buying and selling of goods or services using the internet, and the transfer of money and data to execute these transactions</span></a></h4>
          </div>
        </div>
      </div>

    </div>
  </section>
  <!-- End offers Section -->

  <!-- ======= Services Section ======= -->
  <section routerLink fragment="services" id="services" class="services section-bg" style="background-color: #fff !important;">
    <div class="container">

      <div class="section-title" data-aos="fade-up">
        <h2>Services</h2>
        <p>Waafipay service payment is an innovative new service that puts payers in control of their money, offering them more flexibility over their Direct Debits.</p>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-6" data-aos="fade-up">
          <div class="icon-box">
            <div class="icon"><i class="icofont-chart-histogram"></i></div>
            <h4 class="title"><a href="">Increase Acceptance</a></h4>
            <p class="description">Increase acceptance rate by offering more payment options to your customers.</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay="100">
          <div class="icon-box">
            <div class="icon"><i class="icofont-man-in-glasses"></i></div>
            <h4 class="title"><a href="">Take Control</a></h4>
            <p class="description">Take control of your customer’s online experience by using our SDK for major mobile platforms.</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay="200">
          <div class="icon-box">
            <div class="icon"><i class="icofont-money-bag"></i></div>
            <h4 class="title"><a href="">Acquiring Cost</a></h4>
            <p class="description">Cut down acquiring cost through our local processing and settlement in over 60 countries.</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay="300">
          <div class="icon-box">
            <div class="icon"><i class="icofont-architecture-alt"></i></div>
            <h4 class="title"><a href="">Reduce Fraud</a></h4>
            <p class="description">Reduce fraud and chargeback risks using FloGuard - our state-of the art fraud prevention engine.</p>
          </div>
        </div>

      </div>
      <div class="section-title" data-aos="fade-up">
          <div class="btn-wrap" >
            <a href="#" class="btn btn-getstarted">Get Started</a>
          </div>

      </div>
    </div>
  </section>
  <!-- End Services Section -->