import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'src/app/services/page-title.service';

@Component({
  selector: 'app-shopify',
  templateUrl: './shopify.component.html',
  styleUrls: ['./shopify.component.css']
})
export class ShopifyComponent implements OnInit {

  constructor(
    private pageTitle: PageTitleService
  ) {
    this.pageTitle.setTitle('Shopify')
   }

  ngOnInit(): void {
  }

}
