import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PageTitleService } from 'src/app/services/page-title.service';

@Component({
  selector: 'app-merchant-services',
  templateUrl: './merchant-services.component.html',
  styleUrls: ['./merchant-services.component.css']
})
export class MerchantServicesComponent implements OnInit {

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private pageTitle: PageTitleService
    ) {
      this.pageTitle.setTitle('Merchant Service')
     }

  ngOnInit(): void {
  }
  onClick(){
    let x = document.querySelector("#services");
    if (x){
        x.scrollIntoView();
  }
}

}
