<section id="services" class="services section-bg">
    <div class="container">
        <div class="section-title col" data-aos="fade-up" style="width: 90%;">
            <h2 style="text-align: left;">WooCommerce</h2>
            <p style="margin: 3 0px;" style="text-align: left;">
                The WaafiPay WooCommerce Extension allows merchants who use the WooCommerce system to take payments via the
                WaafiPay Hosted Payment Pages. No card details are captured by or stored within the WooCommerce system, so
                there are no PCI requirements for the store.
            </p>
            <br>
            <p style="margin: 3 0px;" style="text-align: left;">
                When the customer reached the payment stage of the WooCommerce checkout, they will be presented with the
                option to pay using credit or debit card via WaafiPay. This will take them to the WaafiPay secure server to
                enter their card details. The result of the purchase attempt (authorised or otherwise) is sent back to
                the WooCommerce system to update the order details. The customer is also returned to the store.
            </p>
        </div>
    </div>
    <div class="container">
        <div class="section-title col" data-aos="fade-up" style="width: 90%;">
            <h2 style="text-align: left; color: #009e44;">Version Requirements</h2>
            <p style="margin: 3 0px;" style="text-align: left;">
                This has been tested using WooCommerce version v5.5.1

                You should ensure you have the latest available versions of WooCommerce and WordPress.

                Your systems must be using PHP 5.5 or above, and you must have the cURL PHP module installed and
                enabled.
            </p>
            <br>
            <p style="margin: 3 0px;" style="text-align: left;">
                When the customer reached the payment stage of the WooCommerce checkout, they will be presented with the
                option to pay using credit or debit card via WaafiPay. This will take them to the WaafiPay secure server to
                enter their card details. The result of the purchase attempt (authorised or otherwise) is sent back to
                the WooCommerce system to update the order details. The customer is also returned to the store.
            </p>
        </div>
    </div>
    <div class="container">
        <div class="section-title col" data-aos="fade-up" style="width: 90%;">
            <h2 style="text-align: left; color: #009e44;">Download</h2>
            <p style="margin: 3 0px;" style="text-align: left;">
                <a href="https://github.com/waafipay/waafipay_woocommerce/archive/refs/heads/main.zip" target="_blank" rel="noopener noreferrer">Click HERE to download the WooCommerce 1.0.x plugin.</a>
            </p>
            <br>
            <!-- <p style="margin: 3 0px;" style="text-align: left;">
                <a href="http://">Click HERE to download the WooCommerce 3.2.x plugin.</a>
            </p> -->
            <br>
        </div>
    </div>
    <div class="container">
        <div class="section-title col" data-aos="fade-up" style="width: 90%;">
            <h2 style="text-align: left; color: #009e44;">Installation</h2>
            <h5 style="text-align: left; color: #009e44;">You should take a back-up of your system before installing the extension.</h5>
            <p style="margin: 3 0px;" style="text-align: left;">
                This has been tested using WooCommerce version v5.5.1
    
                You should ensure you have the latest available versions of WooCommerce and WordPress.
    
                Your systems must be using PHP 5.5 or above, and you must have the cURL PHP module installed and
                enabled.
            </p>
            <br>
            <p style="margin: 3 0px;" style="text-align: left;">
                The contents of the zip file should be copied into the plugins directory of your WordPress installation. This is generally  wp-content/plugins/
            </p>
            <br>
            <p style="margin: 3 0px;" style="text-align: left;">
                You now need to login to your WordPress admin system in order to complete the installation.
            </p>
        </div>
    </div>
    <div class="container">
        <div class="section-title col" data-aos="fade-up" style="width: 90%;">
            <h2 style="text-align: left; color: #009e44;">Configuring WooCommerce</h2>
            <h5 style="text-align: left; color: #009e44;">Activating the module</h5>
            <p style="margin: 3 0px;" style="text-align: left;">
                Log in to the WordPress administration system, from the side menu select the Plugins option.
            </p>
            <br>
            <p style="margin: 3 0px;" style="text-align: left;">
                This will show a list of all the plugins for your WordPress installation. Locate the line for WaafiPay and click on the Activate link.
            </p>
            <br>
            <img src="assets/img/plugins.jpg" alt="" srcset="">
            <br>

            <h5 style="text-align: left; color: #009e44; margin-top: 80px;">Configuring the module</h5>
            <p style="margin: 3 0px;" style="text-align: left;">
                From the WooCommerce option in the WordPress side menu, click on the Settings option. In the settings display, select the Payment Gateways tab..
            </p>
            <br>
            <!-- <p style="margin: 3 0px;" style="text-align: left;">
                This will show a list of all the plugins for your WordPress installation. Locate the line for WaafiPay and click on the Activate link.
            </p> -->
            <br>
            <img src="assets/img/payments-methods.jpg" alt="" srcset="">

            <h5 style="text-align: left; color: #009e44; margin-top: 80px;">Test Cards And Wallets Or Live Cards And Wallets</h5>
            <p style="margin: 3 0px;" style="text-align: left;">
                Enter Your Ids into these input fields first face you implement the test fields then you can test it then connect to the live <span style="font-size: 18; font-weight: 400; color: #009e44;">waafipay</span> peyments
            </p>
            <br>
            <!-- <p style="margin: 3 0px;" style="text-align: left;">
                This will show a list of all the plugins for your WordPress installation. Locate the line for WaafiPay and click on the Activate link.
            </p> -->
            <br>
            <img src="assets/img/integrations.jpg" alt="" srcset="">
        </div>
    </div>
    <div class="container">
        <div class="section-title col" data-aos="fade-up" style="width: 90%;">
            <p style="text-align: left;">Related Articles</p>
            <ul style="text-align: left; margin-left: 15px;">
                <li style="color: #009e44;"><a href="">Shopify - v1.0x</a></li>
                <li style="color: #009e44;"><a href="">OpenCart - v1.0x</a></li>
            </ul>
        </div>
    </div>
</section>