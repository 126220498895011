<section id="services" class="services section-bg">
    <div class="container">
      <div class="section-title col" data-aos="fade-up">
        <h2>EASY INTEGRATION</h2>
        <div class="lines m-auto"></div>
        <img style="width: 40px; height: 40px; margin-top: 40px;" src="../../../../assets/img/plugin/plugin.png" alt="" srcset="">
        <p style="margin: 3 0px;">INTEGRATE THE WAAFIPAY PAYMENT GATEWAY INTO YOUR WEBSITE, APP OR SHOPPING CART</p>
        <div class="lines m-auto"></div>
       
      </div>  
    </div>
</section>
<section id="faq" class="faq section-bg" style="margin-top: -80px;">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <img style="width: auto; height: 60px;" src="../../../../assets/img/plugin/pcs.png" alt="" srcset="">
      <h3 style="margin-top: 30px;">WEBSITE INTEGRATION</h3>
    </div>

    <div class="faq-list">
      <ul>
        <li data-aos="fade-up" data-aos="fade-" data-aos-delay="100">
         <span data-bs-toggle="collapse" class="collapsed" data-bs-target="#faq-list-1"><i class="bx bx-plus icon-help"></i><i class="bx bx-minus icon-helps"></i></span><a data-bs-toggle="collapse" class="collapsed" data-bs-target="#faq-list-1">OPTION 1: WE HOST THE PAYMENT GATEWAY FOR YOU<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="faq-list-1" class="collapse" data-bs-parent=".faq-list">
            <p>
              Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.
            </p>
          </div>
        </li>

        <li data-aos="fade-up" data-aos-delay="200">
          <span data-bs-toggle="collapse" class="collapsed" data-bs-target="#faq-list-2"><i class="bx bx-plus icon-help"></i><i class="bx bx-minus icon-helps"></i></span> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" class="collapsed">OPTION 2: BRING THE WAAFIPAY PAYMENT FORM INTO YOUR OWN WEBSITE<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list">
            <p>
              Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
            </p>
          </div>
        </li>

        <li data-aos="fade-up" data-aos-delay="300">
          <span data-bs-toggle="collapse" class="collapsed" data-bs-target="#faq-list-3"><i class="bx bx-plus icon-help"></i><i class="bx bx-minus icon-helps"></i></span> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" class="collapsed">OPTION 3: FULLY INTEGRATE THE PAYMENT FORM INTO YOUR WEBSITE<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
            <p>
              Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
            </p>
          </div>
        </li>
      </ul>
    </div>

  </div>
</section>
<!-- ======= Values Section ======= -->
    <!--====== SERVICES PART START ======-->
    <section id="features" class="services-area pt-120">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <div class="section-title text-center pb-40">
                <div class="line m-auto"></div>
                <h3 class="title">
                  SHOPPING CART INTEGRATION
                </h3>
                <p>We have a secure plugin for any of these shopping carts, to help you
                  get your website integrated with our payment gateway in a matter of minutes.</p>
              </div>
              <!-- section title -->
            </div>
          </div>
          <!-- row -->
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-7 col-sm-8">
              <div
                class="single-services text-center mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div class="services-icon">
                  <img
                    class="shape"
                    src="assets/img/plugin/woocommerce.png"
                    alt="shape"
                  />
                  <!-- <img
                    class="shape-1"
                    src="assets/images/services/services-shape.svg"
                    alt="shape"
                  /> -->
                  <i class="lni lni-baloon"> </i>
                </div>
                <div class="services-content mt-30">
                  <h4 class="services-title">
                    <a href="javascript:void(0)">WooCommerce</a>
                  </h4>
                  <!-- <p class="text">
                    Lorem ipsum dolor sit amet,consetetur sadipscing elitr,
                    seddiam nonu eirmod tempor invidunt labore.
                  </p> -->
                  <a class="more" href="/knowledge-base/woocommerce"
                    >Getting Started <i class="lni lni-chevron-right"> </i>
                  </a>
                </div>
              </div>
              <!-- single services -->
            </div>
            <div class="col-lg-4 col-md-7 col-sm-8">
              <div
                class="single-services text-center mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <div class="services-icon">
                  <img 
                    class="shape"
                    src="assets/img/plugin/shopify.png"
                    alt="shape"
                  />
                  <!-- <img
                    class="shape-1"
                    src="assets/images/services/services-shape-2.svg"
                    alt="shape"
                  /> -->
                  <i class="lni lni-cog"> </i>
                </div>
                <div class="services-content mt-30">
                  <h4 class="services-title">
                    <a href="javascript:void(0)">Shopify</a>
                  </h4>
                  <!-- <p class="text">
                    Lorem ipsum dolor sit amet,consetetur sadipscing elitr,
                    seddiam nonu eirmod tempor invidunt labore.
                  </p> -->
                  <a class="more" href="/knowledge-base/shopify"
                    >Getting Started <i class="lni lni-chevron-right"> </i>
                  </a>
                </div>
              </div>
              <!-- single services -->
            </div>
            <div class="col-lg-4 col-md-7 col-sm-8">
              <div
                class="single-services text-center mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.8s"
              >
                <div class="services-icon">
                  <img
                    class="shape"
                    src="assets/img/plugin/opencart.png"
                    alt="shape"
                  />
                  <!-- <img
                    class="shape-1"
                    src="assets/images/services/services-shape-3.svg"
                    alt="shape"
                  /> -->
                  <i class="lni lni-bolt-alt"> </i>
                </div>
                <div class="services-content mt-30">
                  <h4 class="services-title">
                    <a href="javascript:void(0)">OpenCart</a>
                  </h4>
                  <!-- <p class="text">
                    Lorem ipsum dolor sit amet,consetetur sadipscing elitr,
                    seddiam nonu eirmod tempor invidunt labore.
                  </p> -->
                  <a class="more" href="javascript:void(0)"
                    >Getting Started <i class="lni lni-chevron-right"> </i>
                  </a>
                </div>
              </div>
              <!-- single services -->
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- container -->
      </section>
      <!--====== SERVICES PART ENDS ======-->
  
      <section id="about">
        <!--====== ABOUT PART START ======-->
        <div class="about-area pt-70">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div
                  class="about-content mt-50 wow fadeInLeftBig"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <div class="section-title" style="text-align: left !important;">
                    <div class="line"></div>
                    <h3 class="title" style="left: 0;">
                      Payment Methods
                    </h3>
                  </div>
                  <!-- section title -->
                  <p class="text">
                    With one single integration, you become connected to every payment method that waafipay supports in your country. The list of payment methods is ever-growing…
                  </p>
                  <a href="javascript:void(0)" class="main-btn">Try it Free</a>
                </div>
                <!-- about content -->
              </div>
              <div class="col-lg-6">
                <div
                  class="about-image text-center mt-50 wow fadeInRightBig"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <img src="assets/img/payment-1.png" alt="about" />
                </div>
                <!-- about image -->
              </div>
            </div>
            <!-- row -->
          </div>
          <!-- container -->
          <div class="about-shape-1">
            <img src="assets/images/about/about-shape-1.svg" alt="shape" />
          </div>
        </div>
        <!--====== ABOUT PART ENDS ======-->
  
        <!--====== ABOUT PART START ======-->
        <div class="about-area pt-70">
          <div class="about-shape-2">
            <img src="assets/images/about/about-shape-2.svg" alt="shape" />
          </div>
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 order-lg-last">
                <div
                  class="about-content ms-lg-auto mt-50 wow fadeInLeftBig"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <div class="section-title" style="text-align: left !important;">
                    <div class="line"></div>
                    <h3 class="title">
                      Mobile App Integration
                    </h3>
                  </div>
                  <!-- section title -->
                  <p class="text">
                    Build card processing directly into your mobile app. Our mobile API allows your app to capture and send card details to waafipay’s payment gateway for secure processing.
                  </p>
                  <a href="javascript:void(0)" class="main-btn">Try it Free</a>
                </div>
                <!-- about content -->
              </div>
              <div class="col-lg-6 order-lg-first">
                <div
                  class="about-image text-center mt-50 wow fadeInRightBig"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <img src="assets/img/payment-2.png" alt="about" />
                </div>
                <!-- about image -->
              </div>
            </div>
            <!-- row -->
          </div>
          <!-- container -->
        </div>
        <!--====== ABOUT PART ENDS ======-->

      </section>
  
      <!--====== VIDEO COUNTER PART START ======-->
      <section id="facts" class="video-counter pt-70">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 order-lg-last">
              <div
                class="counter-wrapper mt-50 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.8s"
              >
                <div class="counter-content">
                  <div class="section-title" style="text-align: left !important;">
                    <div class="line"></div>
                    <h3 class="title">NEED HELP?</h3>
                  </div>
                  <!-- section title -->
                  <p class="text">
                    Can’t decide out which type of integration is the right one for you? Don’t worry, we will work with you to make sure that the solution you choose is the right one for your requirements.We’ve got comprehensive integration documentation, and a tech support team ready to help get you up and running.
                  </p>
                </div>
                <!-- counter content -->
                <div class="row no-gutters">
                  <div class="col-4">
                    <div
                      class="
                        single-counter
                        counter-color-1
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                    >
                      <div class="counter-items text-center">
                        <span
                          class="count countup text-uppercase"
                          cup-end="125"
                        >125K</span>
  
                        <p class="text">Downloads</p>
                      </div>
                    </div>
                    <!-- single counter -->
                  </div>
                  <div class="col-4">
                    <div
                      class="
                        single-counter
                        counter-color-2
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                    >
                      <div class="counter-items text-center">
                        <span
                          class="count countup text-uppercase"
                          cup-end="345"
                        >345K</span>
                        <p class="text">Active Users</p>
                      </div>
                    </div>
                    <!-- single counter -->
                  </div>
                  <div class="col-4">
                    <div
                      class="
                        single-counter
                        counter-color-3
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                    >
                      <div class="counter-items text-center">
                        <span
                          class="count countup text-uppercase"
                          cup-end="200"
                        >200K</span>
                        <p class="text">User Rating</p>
                      </div>
                    </div>
                    <!-- single counter -->
                  </div>
                </div>
                <!-- row -->
              </div>
              <!-- counter wrapper -->
            </div>
            <div class="col-lg-6">
              <div
                class="video-content mt-50 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <img class="dots" src="assets/images/video/dots.svg" alt="dots" />
                <div class="video-wrapper">
                  <div class="video-image">
                    <img src="assets/img/contact-us.jpg" alt="video" />
                  </div>
                  
                </div>
                <!-- video wrapper -->
              </div>
              <!-- video content -->
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- container -->
      </section>
      <!--====== VIDEO COUNTER PART ENDS ======-->