import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'src/app/services/page-title.service';

@Component({
  selector: 'app-wages-protection',
  templateUrl: './wages-protection.component.html',
  styleUrls: ['./wages-protection.component.css']
})
export class WagesProtectionComponent implements OnInit {

  constructor(
    private pageTitle: PageTitleService
  ) {
    this.pageTitle.setTitle('Wages Protection')
   }

  ngOnInit(): void {
  }

  onClick(){
    let x = document.querySelector("#wages");
    if (x){
        x.scrollIntoView();
  }

}

}
