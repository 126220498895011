import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'src/app/services/page-title.service';

@Component({
  selector: 'app-credit-cars',
  templateUrl: './credit-cars.component.html',
  styleUrls: ['./credit-cars.component.css']
})
export class CreditCarsComponent implements OnInit {

  constructor(
    private pageTitle: PageTitleService
  ) {
    this.pageTitle.setTitle('Credit Cards')
   }

  ngOnInit(): void {
  }

  onClick(){
    let x = document.querySelector("#offers");
    if (x){
        x.scrollIntoView();
  }
}

}
