import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'src/app/services/page-title.service';

@Component({
  selector: 'app-web-inegration',
  templateUrl: './web-inegration.component.html',
  styleUrls: ['./web-inegration.component.css']
})
export class WebInegrationComponent implements OnInit {

  constructor(
    private pageTitle: PageTitleService
  ) { 
    this.pageTitle.setTitle('Easy Integration')
  }

  ngOnInit(): void {
  }

}
