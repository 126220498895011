
<!-- ======= Cars Section ======= -->
<section id="hero" class="d-flex flex-column justify-content-center align-items-center" style="margin-bottom: 0px !important;">
    <div class="container" data-aos="fade-in">
      <div class="row">
        <div class="col-6">
          <h1>WaafiPay Demo.</h1>
          <p>Our WaaafiPay POS and NFC Payment Demos</p>
          <div class="d-flex align-items-center">
            <i class="bx bx-down-arrow-alt get-started-icon"></i>
            <a (click)="onClick()" style="cursor: pointer;" class="btn-get-started scrollto">Learn Our Demo</a>
          </div>
        </div>
        <div class="col-6">
          <img src="../assets/img/demo/demo.png" style="margin-left: 35px;" width="110%" height="100%" alt="" srcset="">
        </div>
      </div>
     
    </div>
  </section>
   <!-- ======= offers Section ======= -->
   <section id="offers" class="offers section-bg">
    <div class="container">

        <div class="section-title" data-aos="fade-up">
            <h2>Our NFC Demo</h2>
            <p>Our payment provision of money transfer services or any other service functional to the transfer of money and includes the issuance of electronic money and electronic funds transfers but does not include the provision of solely online or telecommunication services or network access</p>
          </div>

      <div class="row">
        <div style="width: 500px; height: 400px;" class="col-7  justify-content-center align-items-stretch" data-aos="fade-right">
          <iframe src="../assets/vid/WaafiPayDemo.mp4" width="650" height="380" frameborder="0"></iframe>
        </div>

        <div class="col-5  align-items-stretch justify-content-center">
          <h3 data-aos="fade-up">WaafiPay NFC Payment</h3>
    

          <div class="icon-box" data-aos="fade-up">
            <div class="icon"><i class="icofont-stock-mobile"></i></div>
            <h4 class="title"><a href="">Mobile Wallet Integration - <span style="font-weight: 100;">We Integrating a Master Credit Card Payment Processing solution offers Our applications.</span> </a></h4>
          </div>
          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon"><i class="icofont-mastercard"></i></div>
            <h4 class="title"><a href="">Visa Card Integration - <span style="font-weight: 100;">We Integrating a visa Credit Card Payment Processing solution offers Our applications.</span></a></h4>
          </div>
        </div>
      </div>

    </div>
  </section>
  <!-- End offers Section -->
   <!-- ======= offers Section ======= -->
   <section id="offers" class="offers">
    <div class="container">

        <div class="section-title" data-aos="fade-up">
            <h2>Our POS Demo</h2>
            <p>Our payment provision of money transfer services or any other service functional to the transfer of money and includes the issuance of electronic money and electronic funds transfers but does not include the provision of solely online or telecommunication services or network access</p>
          </div>

      <div class="row">
        <div class="col-5  align-items-stretch justify-content-center">
          <h3 data-aos="fade-up">WaafiPay POS Payment</h3>
    

          <div class="icon-box" data-aos="fade-up">
            <div class="icon"><i class="icofont-stock-mobile"></i></div>
            <h4 class="title"><a href="">Mobile Wallet Integration - <span style="font-weight: 100;">We Integrating a Master Credit Card Payment Processing solution offers Our applications.</span> </a></h4>
          </div>
          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon"><i class="icofont-mastercard"></i></div>
            <h4 class="title"><a href="">Visa Card Integration - <span style="font-weight: 100;">We Integrating a visa Credit Card Payment Processing solution offers Our applications.</span></a></h4>
          </div>
        </div>
        <div style="width: 500px; height: 400px;" class="col-7  justify-content-center align-items-stretch" data-aos="fade-right">
          <iframe src="../assets/vid/WaafiPosDemo.mp4" width="650" height="380" frameborder="0"></iframe>
        </div>
      </div>

    </div>
  </section>
  <!-- End offers Section -->
