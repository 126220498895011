  <!-- ======= Top Bar ======= -->
  <section id="topbar" class="d-none d-lg-block">
    <div class="container d-flex">
      <div class="contact-info mr-auto">
        <!-- <ul>
          <li><i class="icofont-envelope"></i><a href="mailto:contact@example.com">contact@example.com</a></li>
          <li><i class="icofont-phone"></i> +1 5589 55488 55</li>
          <li><i class="icofont-clock-time icofont-flip-horizontal"></i> Mon-Fri 9am - 5pm</li>
        </ul> -->

      </div>
      
      <div class="cta">
        <div class="social-links text-center text-lg-right pt-3 pt-lg-0">
          <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
          <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
          <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
          <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
          <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
          
            <a class="dropdown-toggle dropdown" type="a" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="flag-icon flag-icon-us"></span> English
            </a>
            <div class="dropdown-menu dropdown-menu-right language">
              <a class="dropdown-item" href="#so"><span class="flag-icon flag-icon-so"> </span> Somalia</a>
              <!-- <a class="dropdown-item" href="#it"><span class="flag-icon flag-icon-it"> </span> Italian</a>
              <a class="dropdown-item" href="#ru"><span class="flag-icon flag-icon-ru"> </span> Russian</a> -->
            </div>
        </div>
        
      </div>
    </div>
  </section>


  <!-- ======= Header ======= -->
  <header id="header" class="sticky">
    <div class="container d-flex">

      <div class="logo mr-auto">
        <a href="/"><img src="assets/logos.png" alt="" class="img-fluid"></a>
      </div>

      <nav class="nav-menu d-none d-lg-block">
        <ul>
          <li ><a href="/">Home</a></li>
          <li class="drop-down"><a style="cursor: pointer;">Services</a>
            <ul>
              <li><a href="/merchant-services">Merchant Services</a></li>
              <li><a href="/tap-and-pay">Tap & Pay (Tapsii)</a></li>
              <li><a href="/wages-protection">Wages Protection</a></li>
              <li><a href="/credit-cars">Credit Cards</a></li>
              <li><a href="/easy-integration">Purchase Integration</a></li>
            </ul>
          </li>
          <li><a href="https://developer.waafipay.net/" target="_blank">Developer</a></li>
          <li><a href="/demo">Demo</a></li>
          <li><a href="/about-us">About Us</a></li>
          <li><a href="https://merchant.waafipay.net" target="_blank" class="myaccount">My Account</a></li>
        </ul>
      </nav>

    </div>
  </header>
  <!-- End Header -->